import React from "react";
import { useFormik } from "formik";

import messageService from "./services/messageService";

//import "./app.css"

const validate = (values) => {
  const errors = {};
  if (!values.message) {
    errors.message = "Pakollinen kenttä";
  } else if (values.message.length < 15) {
    errors.message = "Viestin minimipituus on 15-merkkiä";
  }
  return errors;
};

function Form() {
  const [submitted, setSubmitted] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    validate,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      messageService.newMessage(values.message).then(() => {
        setSubmitted(true);
      });
    },
  });

  if (submitted) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <h1 className="text-7xl">Kiitos!</h1>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="flex h-screen">
          <div className="m-auto">
            <div className="py-2">
              <h1 className="text-2xl text-center">
                Lusilantie perusparannushanke puutelista
              </h1>
            </div>
            <div className="block p-6 rounded-lg shadow-lg bg-white max-w-md">
              <div className="px-2 mb-5">
                <p>
                  Kirjoita havaitsemasi puutteet ja ongelmat oheiselle
                  lomakkeelle. Lisää viestiin myös nimesi mikäli haluat ELY:n
                  suorittavan tarkastuksen kiinteistösi kohdalla.
                </p>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group mb-6">
                  <textarea
                    className="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
            "
                    id="message"
                    name="message"
                    type="text"
                    rows="10"
                    placeholder="Kirjoita havaitsemasi puutteet tähän"
                    onChange={formik.handleChange}
                    value={formik.values.message}
                  ></textarea>
                  {formik.errors.message ? (
                    <div className="text-red-500">{formik.errors.message}</div>
                  ) : null}
                </div>
                <button
                  type="submit"
                  className="
            w-full
            px-6
            py-2.5
            bg-blue-600
            text-white
            font-medium
            text-xs
            leading-tight
            uppercase
            rounded
            shadow-md
            hover:bg-blue-700 hover:shadow-lg
            focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
            active:bg-blue-800 active:shadow-lg
            transition
            duration-150
            ease-in-out"
                >
                  Lähetä
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Form;
