import axios from "axios";

const newMessage = (message) => {
  const result = axios.post("https://api.lusilantie.fi/v1/messages", {
    message,
  });

  return result;
};

export default { newMessage };
